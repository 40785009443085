/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        $body.on('loaderEnd', () => console.log('ended'))


        /*
		|
		| menu scroll
		|-----------------
		*/
        $(window).scroll(function () {
            var st = $(this).scrollTop();
            var banner = $('.home');
            setTimeout(function () {
                if ($(this).scrollTop() > 2) {
                    banner.addClass('active');
                }
            }, 0);
        });

        /*
		|
		| chiffres anime
		|-----------------
		*/
        var a = 0;
        $(window).scroll(function () {
            if ($('.chiffre').length) {
                var oTop = $('.chiffre').offset().top - window.innerHeight;

                if (a == 0 && $(window).scrollTop() > oTop) {
                    $('.unity').each(function () {
                        var $this = $(this),
                            countTo = $this.attr('data-count');
                        $({
                            countNum: $this.text()
                        }).animate({
                                countNum: countTo
                            },
                            {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                    //alert('finished');
                                }

                            });
                    });
                    a = 1;
                }
            }
        });

        /*
		|
		| Accordeon
		|-----------------
		*/
        $('.toggle').click(function (e) {
            e.preventDefault();
            let $this = $(this);

            jQuery('.toggle').removeClass('active');
            $this.addClass('active');

            if ($this.next().hasClass('show')) {
                $this.next().removeClass('show');
                $this.removeClass('active');
                $this.next().slideUp(350);
            } else {
                $this.parent().parent().find('li .inner').removeClass('show');
                $this.parent().parent().find('li .inner').slideUp(350);
                $this.next().toggleClass('show');
                $this.next().slideToggle(350);
            }
        });

        /* team modal */
        if (jQuery('.mkx-modal-loop').length) {
            jQuery('.mkx-modal-loop').iziModal({});
        }

        /*
        |
        | News
        |-----------------
        */
        var swipernews = new Swiper('.swiperNews', {
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                30: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        });

    }
}


